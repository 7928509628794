<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success" v-if="loaded">
      <!-- Card stats -->
      <b-row>
        <b-col
          xl="3"
        >
          <base-input label="Type">
            <el-select
              v-model="filters.type"
              filterable
              placeholder="Select type"
              class="trigger-type"
            >
              <el-option
                v-for="(label, option) in types"
                :key="option"
                :label="label"
                :value="option"
              />
            </el-select>
          </base-input>
        </b-col>

        <b-col
          xl="1"
        >
          <base-button
            class="search-button"
            type="info"
            @click="search"
          >
            Search
          </base-button>
        </b-col>
        <b-col
          xl="8"
        >
          <base-button
            style="display: block; float: right"
            class="search-button"
            type="success"
            @click="create"
          >
            Create new
          </base-button>
        </b-col>
      </b-row>
    </base-header>
    <b-container
      fluid
      class="mt--7"
      v-if="loaded"
    >
      <b-row>
        <b-col>

          <b-card
            v-if="loaded"
            no-body
          >
            <el-table
              class="table-responsive table"
              header-row-class-name="thead-light"
              :data="triggers"
              @row-click="open"
            >
              <el-table-column
                label="ID"
                min-width="200px"
                prop="id"
              />
              <el-table-column
                label="Type"
                min-width="160px"
                prop="type"
              />
              <el-table-column
                label="Updated By"
                min-width="160px"
                prop="email"
              />
              <el-table-column
                label="Last Update"
                min-width="200px"
                prop="updated_at"
              />
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end">
              <base-pagination
                v-model="page"
                :per-page="limit"
                :total="total"
                @change="pageUpdated"
              />
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {Select, Option, Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from 'element-ui';
import {mapGetters} from "vuex";

export default {
  name: "TriggerList",
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      types: {
        log: 'Log',
        execute_snapshot: 'Execute Snapshot',
      },
      filters: {
        sortBy: 'updated_at',
        order: 'desc',
      },
      page: 1,
      limit: 10,
      loaded: false,
    }
  },
  computed: {
    ...mapGetters({
      triggers: 'triggers/getTriggers',
      total: 'triggers/getTriggersNumber',
      user: 'authentication/getUser',
      storeFilters: 'triggers/getFilters'
    }),
  },
  beforeMount() {
    return this.pageUpdated(this.page)
      .then(() => this.loaded = true)
  },
  methods: {
    pageUpdated(page) {
      return this.$store.dispatch(
        'triggers/getTriggers',
        this.getFilters({page, limit: this.limit})
      )
    },
    search() {
      const filters = {
        sortBy: 'updated_at',
        order: 'desc',
      }

      this.filters = filters
      this.$store.commit('triggers/setFilters', filters)
    },
    getFilters(filters) {
      return this.filters
        ? Object.assign({}, this.filters, filters)
        : filters
    },
    open(row) {
      return this.$router.push(`/debezium-signal/${row.id}`);
    },
    create() {
      return this.$router.push(`/debezium-signal/new`);
    },
  }
}
</script>

<style scoped>
@media (min-width: 768px) {
  .search-button {
    margin-top: 30px;
  }
}

.trigger-type {
  width: 100%;
}
</style>
