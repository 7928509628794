<template>
  <div>
    <div class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center profile-header">
      <b-container fluid>
        <span class="mask bg-gradient-success opacity-8" />
      </b-container>
    </div>
    <b-container
      fluid
      class="mt--6"
    >
      <b-col
        xl="12"
        class="order-xl-1"
      >
        <card>
          <b-row
            slot="header"
            align-v="center"
          >
            <b-col cols="4">
              <h3 class="mb-0">
                Create Signal
              </h3>
            </b-col>
          </b-row>

          <b-form
            @submit.prevent="submit"
          >
            <div class="pl-lg-4">
              <b-row>
                <b-col lg="4">
                  <base-input label="Type">
                    <el-select
                      v-model="signal.type"
                      style="margin-top: 3%"
                      filterable
                      placeholder="Select type"
                      class="trigger-type"
                    >
                      <el-option
                        v-for="(label, option) in types"
                        :key="option"
                        :label="label"
                        :value="option"
                      />
                    </el-select>
                  </base-input>
                </b-col>
                <b-col lg="8">
                  <div class="pl-lg-4">
                    <label class="form-control-label"> Data </label>
                    <div
                      style="margin-top: 1.4%"
                      class="json-editor"
                    >
                      <b-form-textarea
                        v-model="signal.data"
                        class="form-control"
                        rows="30"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form>
          <b-row
            class="float-right"
            style="margin-top: 2%"
          >
            <b-col>
              <base-button
                type="info"
                @click="formatJson"
              >
                Format JSON
              </base-button>
            </b-col>
          </b-row>
          <b-row
            slot="footer"
          >
            <base-button
              type="success"
              @click="openModal"
            >
              Submit
            </base-button>
            <base-button
              type="danger"
              @click="cancel"
            >
              Cancel
            </base-button>
          </b-row>
        </card>
      </b-col>
    </b-container>
    <modal
      :show="isModalOpen"
      :accept-callback="submit"
      :body="modalBody"
    />
  </div>
</template>
<script>
import * as R from 'ramda';
import {mapGetters} from 'vuex';
import {Select, Option} from 'element-ui';
import Modal from '@/components/Modal'

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
  },
  data() {
    return {
      modalBody: 'Do you want to save these changes?',
      signal: {
        type: 'log',
        data: ''
      },
      types: {
        log: 'Log',
        'execute-snapshot': 'Execute Snapshot',
      },
      isDestroyed: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/getUser',
      isModalOpen: 'isModalOpen'
    }),
  },
  beforeDestroy() {
    this.isDestroyed = true
  },
  methods: {
    openModal() {
      return this.$store.commit('setModalStatus', true)
    },
    submit() {
      if (isNullOrEmpty(this.signal.data)) {
        this.$store.dispatch('notification/notify', {
          type: 'danger',
          message: 'Fill all the fields before submitting'
        })
        return
      }

      if (typeof this.signal.data === 'string') {
        if (!this.formatJson()) {
          return
        }
      }

      return this.$store.dispatch('triggers/createTrigger', this.signal)
        .then((data) => {
          console.log(data)
          this.$store.dispatch('notification/notify', {
            type: 'info',
            message: 'Signal Created',
            onClose: () => {
              if (!this.isDestroyed) {
                return this.$router.push(`/debezium-signal/${data.data.data.id}`)
              }
            }
          })
        })
        .catch((err) => {
          this.$store.dispatch('notification/notify', {
            type: 'danger',
            message: err.message
          })
        })
    },
    cancel() {
      return this.$router.push(`/debezium-signal`);
    },
    formatJson() {
      try {
        this.signal.data = JSON.parse(this.signal.data)
        return true
      } catch (_) {
        this.$store.dispatch('notification/notify', {
          type: 'danger',
          message: 'JSON is invalid.'
        })
        return false
      }
    }
  }
};
</script>

<style scoped>
.trigger-type {
  width: 100%;
  display: flex;
}
</style>
