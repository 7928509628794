<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col
              xl="5"
              lg="6"
              md="8"
              class="px-5"
            >
              <h1 class="text-white">
                Login
              </h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col
          lg="5"
          md="7"
        >
          <b-card
            no-body
            class="bg-secondary border-0 mb-0"
          >
            <b-card-header class="bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-3">
                <vue-element-loading
                  :active="processing"
                  :is-full-screen="false"
                />
              </div>
            </b-card-header>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { isNullOrEmpty } from '@/helpers'

export default {
  name: "Processing",
  data() {
    return {
      processing: true,
    }
  },
  beforeMount() {
    if (!this[this.$route.params.type]) {
      return this.finish('Invalid login method. Contact the administrator.')
    }

    return this[this.$route.params.type](this.$route.query)
  },
  methods: {
    google(params) {
      if (isNullOrEmpty(params.code)) {
        return this.finish('Invalid request. Please try again.')
      }

      return this.$store.dispatch('authentication/loginWithGoogle', params.code)
        .then(() => this.finish())
        .catch((error) => {
          if ([400, 401, 403].includes(error.response.status)) {
            return this.finish(error.response.data.message)
          }

          return this.finish('Internal error. Please try again.')
        })
    },
    finish(msg = null) {
      this.processing = false

      if (msg) {
        this.$store.commit('authentication/setError', msg)
        return this.$router.push('/');
      }

      return this.$router.push(`/dashboard`);
    }
  }
}
</script>

<style scoped>

</style>
