<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col
              xl="5"
              lg="6"
              md="8"
              class="px-5"
            >
              <h1 class="text-white">
                Welcome!
              </h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col
          lg="5"
          md="7"
        >
          <b-card
            no-body
            class="bg-secondary border-0 mb-0"
          >
            <b-card-header class="bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <GoogleCard />
            </b-card-header>
            <b-row class="justify-content-center">
              <b-col
                lg="10"
              >
                <div
                  v-if="error"
                  class="alert text-center alert-danger"
                >
                  {{ error }}
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import GoogleCard from '@/components/Authentication/Google'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      GoogleCard
    },
    computed: {
      ...mapGetters({
        error: 'authentication/getError'
      })
    },
    beforeCreate() {
      if (!localStorage.getItem('token')) {
        return
      }

      this.$store.commit('addLoadingRequest')
      return this.$store.dispatch('authentication/validate')
        .then(() => this.$router.push('/dashboard'))
        .catch(() => {
          this.$store.commit('authentication/setError', 'Session expired. Please login again')
          localStorage.removeItem('token')
        })
        .finally(() => this.$store.commit('removeLoadingRequest'))
    },
    beforeDestroy() {
      this.$store.commit('authentication/setError', null)
    }
  };
</script>
