<template>
  <div class="btn-wrapper text-center">
    <a
      :href="url"
      class="btn btn-neutral btn-icon"
    >
      <span class="btn-inner--icon"><img src="img/icons/google.svg"></span>
      <span class="btn-inner--text">Google</span>
    </a>
  </div>
</template>

<script>
import { OAuth2Client } from 'google-auth-library';
import config from '@/config'

export default {
  name: "Google",
  data() {
    return {
      url: ''
    }
  },
  beforeMount() {
    this.key = config.getParameter('client_id')

    const oauth2Client = new OAuth2Client(
      {
        clientId: this.key,
        redirectUri: `${window.location.origin}/login/google`
      }
    )

    this.url = oauth2Client.generateAuthUrl({
      scope: 'profile email'
    })
  }
}
</script>

<style scoped>

</style>
