<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <b-row>
        <b-col
          xl="12"
          class="order-xl-1"
        >
          <a
            v-if="hasPermission(user.role, 'WRITE')"
            class="btn btn-info edit-trigger"
            @click="editTrigger"
          >Edit trigger</a>
        </b-col>
      </b-row>
    </base-header>
    <b-container
      v-if="trigger"
      fluid
      class="mt--6"
    >
      <b-col
        xl="12"
        class="order-xl-1"
      >
        <card>
          <b-row
            slot="header"
            align-v="center"
          >
            <b-col cols="12">
              <h3 class="mb-0">
                Details
              </h3>
            </b-col>
          </b-row>

          <div class="pl-lg-4">
            <b-row>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">ID</span>
                  <span class="description">{{ trigger.id }}</span>
                </div>
              </b-col>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">Type</span>
                  <span class="description">{{ trigger.type }}</span>
                </div>
              </b-col>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">Updated By</span>
                  <span class="description">{{ trigger.email }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">Created At</span>
                  <span class="description">{{ trigger.created_at }}</span>
                </div>
              </b-col>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">Updated At</span>
                  <span class="description">{{ trigger.updated_at }}</span>
                </div>
              </b-col>
            </b-row>
          </div>

          <hr class="my-4">
          <b-col cols="8">
            <h3 class="mb-0">
              Payload
            </h3>
          </b-col>

          <div
            v-if="trigger.dataParsed"
            class="pl-lg-4"
          >
            <b-row>
              <b-col
                lg="12"
                class="d-flex justify-content-center mt-md-5"
              >
                <json-view
                  :data="trigger.dataParsed"
                />
              </b-col>
            </b-row>
          </div>
        </card>
      </b-col>
    </b-container>
  </div>
</template>
<script>
import {JSONView} from 'vue-json-component';
import {mapGetters} from 'vuex';
import { hasPermission } from '@/helpers';

export default {
  components: {
    'json-view': JSONView,
  },
  data() {
    return {
      id: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters({
      trigger: 'triggers/getTrigger',
      user: 'authentication/getUser'
    }),
  },
  beforeMount() {
    return this.$store.dispatch('triggers/getTriggerById', this.id);
  },
  beforeDestroy() {
    return this.$store.commit('triggers/setTrigger', null)
  },
  methods: {
    editTrigger() {
      return this.$router.push(`/debezium-signal/${this.id}/edit`);
    },
    hasPermission(role, targetRole) {
      return hasPermission(role, targetRole)
    }
  }
};
</script>
<style scoped>
.edit-trigger {
  margin-left: 15px;
}

.trigger-state {
  width: 100%;
}
</style>
