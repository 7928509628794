<template>
  <b-card
    body-class="p-0"
    header-class="border-0"
  >
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h3 class="mb-0">
            Latest Triggers
          </h3>
        </b-col>
        <b-col class="text-right">
          <a
            class="btn btn-sm btn-primary"
            @click="openTriggers"
          >See all</a>
        </b-col>
      </b-row>
    </template>

    <el-table
      class="table-responsive table"
      :data="triggers"
      header-row-class-name="thead-light"
      @row-click="openTrigger"
    >
      <el-table-column
        label="ID"
        min-width="200px"
        prop="id"
      />
      <el-table-column
        label="Type"
        min-width="160px"
        prop="type"
      />
      <el-table-column
        label="Updated By"
        min-width="160px"
        prop="email"
      />
      <el-table-column
        label="Last Update"
        min-width="200px"
        prop="updated_at"
      />
    </el-table>
  </b-card>
</template>
<script>
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from 'element-ui'
import { mapGetters } from 'vuex';

export default {
  name: 'LatestTriggersTable',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  computed: {
    ...mapGetters({
      triggers: 'triggers/getTriggers',
    }),
  },
  methods: {
    openTrigger(row) {
      return this.$router.push(`/debezium-signal/${row.id}`);
    },
    openTriggers() {
      return this.$router.push('/debezium-signals');
    }
  }
}
</script>
