<template>
  <div>
    <div class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center profile-header">
      <b-container fluid>
        <span class="mask bg-gradient-success opacity-8" />
      </b-container>
    </div>
    <!--Charts-->
    <b-container
      fluid
      class="mt--7"
    >
      <!--Tables-->
      <b-row class="mt-5">
        <b-col
          xl="12"
          class="mb-5 mb-xl-0"
        >
          <LatestTriggersTable/>
        </b-col>
      </b-row>
      <!--End tables-->
    </b-container>
  </div>
</template>
<script>

// Tables
import LatestTriggersTable from './Dashboard/LatestTriggersTable';

export default {
  components: {
    LatestTriggersTable,
  },
  beforeCreate() {
    return this.$store.dispatch('triggers/getLatestTriggers');
  }
};
</script>
<style>
.el-table .cell {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
