<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <b-row>
        <b-col
          xl="12"
          class="order-xl-1"
        >
          <a
            class="btn btn-info edit-user"
            @click="editUser"
          >Edit User</a>
        </b-col>
      </b-row>
    </base-header>
    <b-container
      v-if="user"
      fluid
      class="mt--6"
    >
      <b-col
        xl="12"
        class="order-xl-1"
      >
        <card>
          <b-row
            slot="header"
            align-v="center"
          >
            <b-col cols="12">
              <h3 class="mb-0">
                Details
              </h3>
            </b-col>
          </b-row>

          <div class="pl-lg-4">
            <b-row>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">Name</span>
                  <span class="description">{{ user.name }}</span>
                </div>
              </b-col>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">Email</span>
                  <span class="description">{{ user.email }}</span>
                </div>
              </b-col>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">Role</span>
                  <span class="description">{{ user.role }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">Active</span>
                  <i
                    v-if="user.is_active"
                    class="fas fa-check state-done"
                  />
                  <i
                    v-if="!user.is_active"
                    class="fas fa-times state-error"
                  />
                </div>
              </b-col>
              <b-col
                lg="4"
                class="card-profile-stats d-flex justify-content-center mt-md-5"
              >
                <div>
                  <span class="heading">Last Update</span>
                  <span class="description">{{ user.updated_at }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </card>
      </b-col>
    </b-container>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      id: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/getUser',
    }),
  },
  beforeMount() {
    return this.$store.dispatch('users/getUser', this.id);
  },
  beforeDestroy() {
    return this.$store.commit('users/setUser', null)
  },
  methods: {
    editUser() {
      return this.$router.push(`/users/${this.id}/edit`);
    }
  }
};
</script>
<style scoped>
.edit-user {
  margin-left: 15px;
}
</style>
