<template>
  <div>
    <b-row>
      <b-col
        xl="12"
        class="order-xl-1"
      >
        <a
          class="btn btn-danger edit-trigger"
          @click="openModal"
        >Delete trigger</a>
      </b-col>
    </b-row>
    <modal
      :show="isModalOpen"
      :accept-callback="deleteTrigger"
      :body="modalBody"
      :warning="modalWarning"
    />
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import Modal from '@/components/Modal'

export default {
  components: {
    Modal
  },
  data() {
    return {
      modalBody: 'Do you want to delete this signal?',
      modalWarning: null,
      destroyed: false,
    }
  },
  computed: {
    ...mapGetters({
      trigger: 'triggers/getTrigger',
      isModalOpen: 'isDeleteModalOpen',
    }),
  },
  beforeDestroy() {
    this.destroyed = true
  },
  methods: {
    openModal() {
      return this.$store.commit('setDeleteModalStatus', true)
    },
    deleteTrigger() {
      return this.$store.dispatch('triggers/deleteTrigger', this.trigger.id)
        .then(() => {
          this.$store.dispatch('notification/notify', {
            type: 'info',
            message: 'Signal deleted',
            onClose: () => {
              return this.$router.push(`/debezium-signal`)
            }
          })
        })
        .catch((err) => {
          this.$store.dispatch('notification/notify', {
            type: 'danger',
            message: err.message
          })
        })
    }
  }
};
</script>

<style scoped>
.edit-trigger {
  margin-left: 15px;
}
</style>
