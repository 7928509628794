<template>
  <div>
    <div class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center profile-header">
      <b-container fluid>
        <span class="mask bg-gradient-success opacity-8" />
      </b-container>
    </div>
    <b-container
      fluid
      class="mt--6"
    >
      <b-col
        xl="12"
        class="order-xl-1"
      >
        <card>
          <b-row
            slot="header"
            align-v="center"
          >
            <b-col cols="4">
              <h3 class="mb-0">
                Edit user
              </h3>
            </b-col>
          </b-row>

          <b-form
            v-if="user"
            @submit.prevent="submit"
          >
            <h6 class="heading-small text-muted mb-4">
              User details
            </h6>
            <div class="pl-lg-4">
              <b-row>
                <b-col lg="6">
                  <base-input
                    v-model="user.name"
                    type="text"
                    label="Name"
                    placeholder="Name"
                  />
                </b-col>
                <b-col lg="6">
                  <base-input
                    v-model="user.email"
                    type="text"
                    label="Email"
                    placeholder="email"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <base-input label="Role">
                    <el-select
                      v-model="user.role"
                      filterable
                      placeholder="Select role"
                      class="user-role-select"
                    >
                      <el-option
                        v-for="role in roles"
                        :key="role"
                        :label="role"
                        :value="role"
                      />
                    </el-select>
                  </base-input>
                </b-col>
                <b-col lg="6">
                  <label class="form-control-label">Is active</label>
                  <b-form-checkbox
                    v-model="user.is_active"
                    :value="1"
                    :unchecked-value="0"
                  />
                </b-col>
              </b-row>
            </div>
          </b-form>
          <b-row
            slot="footer"
          >
            <base-button
              type="success"
              @click="openModal"
            >
              Submit
            </base-button>
            <base-button
              type="danger"
              @click="cancel"
            >
              Cancel
            </base-button>
          </b-row>
        </card>
      </b-col>
    </b-container>
    <modal
      :show="isModalOpen"
      :accept-callback="submit"
      :body="modalBody"
    />
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import { Select, Option } from 'element-ui';
import Modal from '@/components/Modal'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Modal
  },
  data() {
    return {
      id: this.$route.params.id,
      modalBody: 'Do you want to save these changes?',
      roles: [
        'ADMIN',
        'WRITE',
        'READ'
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/getUser',
      isModalOpen: 'isModalOpen'
    }),
  },
  beforeMount() {
    return this.$store.dispatch('users/getUser', this.id)
  },
  beforeDestroy() {
    return this.$store.commit('users/setUser', null)
  },
  methods: {
    openModal() {
      return this.$store.commit('setModalStatus', true)
    },
    submit() {
      return this.$store.dispatch('users/editUser')
      .then(() => {
        this.$store.dispatch('notification/notify',{
          type: 'info',
          message: 'User updated',
          onClose: () => {
            return this.$router.push(`/users/${this.id}`)
          }
        })
      })
      .catch((err) => {
        this.$store.dispatch('notification/notify',{
          type: 'danger',
          message: err.message
        })
      })
    },
    cancel() {
      return this.$router.push(`/users/${this.id}`);
    }
  }
};
</script>

<style scoped>
.user-role-select {
  width: 100%;
  display: flex;
}
</style>
