<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <TriggerDelete v-if="hasPermission(user.role, 'ADMIN')"/>
    </base-header>
    <b-container
      fluid
      class="mt--6"
    >
      <b-col
        xl="12"
        class="order-xl-1"
      >
        <card>
          <b-row
            slot="header"
            align-v="center"
          >
            <b-col cols="4">
              <h3 class="mb-0">
                Edit Signal
              </h3>
            </b-col>
          </b-row>

          <b-form
            v-if="trigger"
            @submit.prevent="submit"
          >
            <div class="pl-lg-4">
              <b-row>
                <b-col lg="6">
                  <base-input
                    v-model="trigger.id"
                    type="text"
                    label="ID"
                    disabled=""
                    placeholder="ID"
                  />
                </b-col>
                <b-col lg="6">
                  <base-input
                    v-model="trigger.email"
                    type="text"
                    readonly
                    label="Updated By"
                    placeholder="updated_by"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <base-input label="Type">
                    <el-select
                      v-model="trigger.type"
                      filterable
                      placeholder="Select type"
                      class="trigger-type"
                    >
                      <el-option
                        v-for="(label, option) in types"
                        :key="option"
                        :label="label"
                        :value="option"
                      />
                    </el-select>
                  </base-input>
                </b-col>
              </b-row>
            </div>
            <hr class="my-4">
            <div class="pl-lg-4">
              <h3 class="mb-0">
                Data
              </h3>
              <div
                v-if="trigger.dataParsed"
                class="json-editor"
              >
                <b-form-textarea
                  v-model="trigger.dataParsed"
                  class="form-control"
                  rows="20"
                />
              </div>
              <b-row
                class="float-right"
                style="margin-top: 2%"
              >
                <b-col>
                  <base-button
                    type="info"
                    @click="formatJson"
                  >
                    Format JSON
                  </base-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
          <b-row
            slot="footer"
          >
            <base-button
              type="success"
              @click="openModal"
            >
              Submit
            </base-button>
            <base-button
              type="danger"
              @click="cancel"
            >
              Cancel
            </base-button>
          </b-row>
        </card>
      </b-col>
    </b-container>
    <modal
      :show="isModalOpen"
      :accept-callback="submit"
      :body="modalBody"
      :warning="modalWarning"
    />
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import { Select, Option } from 'element-ui';
import TriggerDelete from './TriggerDelete';
import Modal from '@/components/Modal'
import { hasPermission } from '@/helpers';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    TriggerDelete,
    Modal
  },
  data() {
    return {
      id: this.$route.params.id,
      modalBody: 'Do you want to save these changes?',
      modalWarning: null,
      types: {
        log: 'Log',
        'execute-snapshot': 'Execute Snapshot',
      },
      destroyed: false,
    }
  },
  computed: {
    ...mapGetters({
      trigger: 'triggers/getTrigger',
      isModalOpen: 'isModalOpen',
      user: 'authentication/getUser',
    }),
  },
  beforeMount() {
    return this.$store.dispatch('triggers/getTriggerById', this.id)
  },
  beforeDestroy() {
    this.destroyed = true
    return this.$store.commit('triggers/setTrigger', null)
  },
  methods: {
    hasPermission(role, targetRole) {
      return hasPermission(role, targetRole)
    },
    openModal() {
      if (this.hasPayloadBeenChanged()) {
        this.modalWarning = 'Data payload has been changed!'
      } else {
        this.modalWarning = null
      }

      return this.$store.commit('setModalStatus', true)
    },
    submit() {
      if (!this.formatJson()) {
        return
      }

      return this.$store.dispatch('triggers/updateTrigger')
      .then(() => {
        this.$store.dispatch('notification/notify',{
          type: 'info',
          message: 'Signal updated',
          onClose: () => {
            if (!this.destroyed) {
              return this.$router.push(`/debezium-signal/${this.id}`)
            }
          }
        })
      })
      .catch((err) => {
        this.$store.dispatch('notification/notify',{
          type: 'danger',
          message: err.message
        })
      })
    },
    cancel() {
      return this.$router.push(`/debezium-signal/${this.id}`);
    },
    hasPayloadBeenChanged() {
      return JSON.stringify(JSON.parse(this.trigger.data)) !== JSON.stringify(this.trigger.dataParsed)
    },
    formatJson() {
      try {
        if (typeof this.trigger.dataParsed === 'string') {
          this.trigger.dataParsed = JSON.parse(this.trigger.dataParsed)
        } else {
          this.trigger.dataParsed = JSON.parse(JSON.stringify(this.trigger.dataParsed))
        }

        return true
      } catch (_) {
        this.$store.dispatch('notification/notify', {
          type: 'danger',
          message: 'JSON is invalid.'
        })
        return false
      }
    }
  }
};
</script>

<style scoped>
.trigger-type {
  width: 100%;
  display: flex;
}

.json-editor {
  margin-top: 1%;
}
</style>
