<template>
  <div>
    <div class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center profile-header">
      <b-container fluid>
        <span class="mask bg-gradient-success opacity-8" />
      </b-container>
    </div>

    <b-container
      fluid
      class="mt--7"
    >
      <b-row>
        <b-col>
          <b-card no-body>
            <el-table
              class="table-responsive table"
              header-row-class-name="thead-light"
              :data="users"
              @row-click="openUser"
            >
              <el-table-column
                label="Application"
                min-width="200px"
                prop="name"
              />
              <el-table-column
                label="Email"
                min-width="200px"
                prop="email"
              />
              <el-table-column
                label="Last Update"
                min-width="200px"
                prop="updated_at"
              />
              <el-table-column
                label="Active"
                min-width="200px"
                prop="is_active"
              >
                <template v-slot="{row}">
                  <i
                    v-if="row.is_active"
                    class="fas fa-check state-done"
                  />
                  <i
                    v-if="!row.is_active"
                    class="fas fa-times state-error"
                  />
                </template>
              </el-table-column>
              <el-table-column
                label="Role"
                min-width="200px"
                prop="role"
              />
            </el-table>

            <b-card-footer class="py-4 d-flex justify-content-end">
              <base-pagination
                v-model="page"
                :per-page="limit"
                :total="total"
                @change="pageUpdated"
              />
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from "element-ui";
import {mapGetters} from "vuex";

export default {
  name: "UserList",
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      page: 1,
      limit: 10
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/getUsers',
      total: 'users/getUsersNumber',
    }),
  },
  beforeMount() {
    return this.pageUpdated(this.page)
  },
  methods: {
    pageUpdated(page) {
      return this.$store.dispatch('users/getUsers', {
        page,
        limit:
        this.limit,
        sortBy: 'name'
      })
    },
    openUser(row) {
      return this.$router.push(`/users/${row.id}`);
    }
  }
}
</script>

<style scoped>

</style>
